import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  description?: string
  title?: string
}

export const Info = memo(function Info({ description, title }: Props) {
  if (!description) {
    return null
  }

  return (
    <Container>
      {title ? <Title>{title}</Title> : null}

      <Wrapper>
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 12.5rem auto;
  padding: 0 1.875rem;
  text-align: center;

  @media (max-width: 1023px) {
    margin-top: 8.5rem;
    margin-bottom: 8.5rem;
    padding: 0;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 3.125rem;
  margin-bottom: 1.875rem;

  @media (max-width: 1023px) {
    font-size: 1.375rem;
    line-height: 1.875rem;
    margin-bottom: 1.5rem;
    padding: 0 1.3125rem;
  }
`

const Wrapper = styled.div`
  max-width: 59.75rem;
  border: 0.625rem solid ${({ theme }) => theme.colors.variants.neutralDark2};
  border-radius: 3.75rem;
  margin: auto;
  padding: 3.75rem 5vw;

  @media (max-width: 1023px) {
    max-width: none;
    border-radius: 0;
    border-right: 0;
    border-left: 0;
    padding: 2.25rem 21px;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  line-height: 2.125rem;

  p {
    margin-block-end: 3.75rem;
    &:last-of-type {
      margin-block-end: 0;
    }
  }

  @media (max-width: 1023px) {
    font-size: 1rem;
    line-height: 2rem;
  }
`
